<template>
  <div class="question-checkboxes-component">
    <el-form-item>
      <el-checkbox-group v-model="checkboxAnswers">
        <el-checkbox
          v-for="option in question.options"
          :key="option"
          :label="option"
          border>
          {{ option }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <QuestionOther
      v-if="showOtherOption"
      :selected="otherOptionSelected"
      :preloaded-answer="otherOptionAnswer"
      @input="updateOtherOptionAnswer">
      <el-checkbox v-model="otherOptionSelected" label="Other" border />
    </QuestionOther>
  </div>
</template>

<script>
import QuestionOther from './subtype/QuestionOther';

export default {
  components: { QuestionOther },
  props: {
    value: {
      type: Array,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkboxAnswers: [],
      otherOptionAnswer: '',
      otherOptionSelected: false
    };
  },
  computed: {
    showOtherOption() {
      return this.question.hasOwnProperty('option_other');
    }
  },
  watch: {
    otherOptionAnswer() {
      this.updateAllAnswers();
    },
    checkboxAnswers() {
      this.updateAllAnswers();
    },
    otherOptionSelected() {
      this.updateAllAnswers();
    }
  },
  beforeMount() {
    const answers = this.value.slice();
    this.checkboxAnswers = answers.filter(option => this.question.options.includes(option));
    this.otherOptionAnswer = answers.filter(option => !this.question.options.includes(option))[0];
    this.otherOptionSelected = !!this.otherOptionAnswer;
  },
  methods: {
    updateOtherOptionAnswer(value) {
      this.otherOptionAnswer = value;
    },
    updateAllAnswers() {
      const answers = this.checkboxAnswers.slice(0);
      if (this.otherOptionSelected) {
        answers.push(this.otherOptionAnswer);
      }
      this.$emit('input', answers);
    }
  }
};
</script>

<style lang="scss">
.question-checkboxes-component {
  .el-form-item {
    margin-bottom: 12px;
  }

  .el-checkbox {
    align-items: center;
    background: $--jb-bg-primary-color;
    display: flex;
    flex-direction: row-reverse;
    font-size: $--font-size-base;
    font-weight: 600;
    height: 60px;
    justify-content: center;
    line-height: $--jb-icon-checkmark-size;
    margin: 0 0 16px 0;
    padding: $--jb-checkbox-padding;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;

    &__input {
      position: absolute;
      right: 22px;
      top: $--jb-icon-checkmark-size;
      transform: scale(2);
      border-color: inherit;

      &.is-focus .el-checkbox__inner {
        border-color: $--border-color-base;
      }
    }

    &__label {
      font-size: $--font-size-base;
      font-weight: 600;
      padding-left: $--jb-checkbox-label-padding-left;
      max-width: 80%;
      white-space: normal;
      margin-left: 0;
    }

    &:hover,
    &.is-checked {
      background: #f3f5fd;
      border-color: $--jb-light-primary-color;
      color: $--jb-light-primary-color;

      .el-checkbox__inner {
        border-color: $--jb-light-primary-color;
      }
    }

    &:hover,
    &:focus {
      transform: translateY(-3px);
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
