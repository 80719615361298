<template>
  <div class="question-address-component">
    <transition name="el-fade-in" mode="out-in">
      <div v-if="showAddressSearch" key="address-search-container">
        <el-form-item class="floating-label inline-select" :class="{ is_focus }">
          <el-select
            id="address-search"
            v-model="address"
            v-select-close-on-blur
            :remote-method="handleAddressSearch"
            :loading="searchingAddress"
            loading-text="Loading ..."
            no-match-text="No Match Found"
            filterable
            remote
            placeholder="Address"
            popper-class="mw-550 inline"
            :popper-append-to-body="false"
            class="form-item-input"
            @visible-change="open = !open"
            @change="handleAddressSelected">
            <el-option
              v-for="suggestion in suggestions"
              :key="suggestion.description"
              :label="suggestion.description"
              :value="suggestion.description" />
          </el-select>
        </el-form-item>
      </div>
      <div v-else key="address-details-container">
        <el-form-item class="floating-label">
          <el-input
            id="address"
            v-model="value.address"
            type="text"
            placeholder="Address"
            class="form-item-input"
            required />
        </el-form-item>
        <el-form-item class="floating-label">
          <el-input
            id="address_2"
            v-model="value.address_2"
            type="text"
            placeholder="Unit, Appartment, etc.."
            class="form-item-input" />
        </el-form-item>
        <el-form-item class="floating-label">
          <el-input
            id="postal_code"
            v-model="value.postal_code"
            type="text"
            placeholder="Postal Code"
            class="form-item-input"
            required />
        </el-form-item>
        <el-form-item class="floating-label">
          <el-input
            id="city"
            v-model="value.city"
            type="text"
            placeholder="City"
            class="form-item-input"
            required />
        </el-form-item>
        <el-form-item class="floating-label">
          <el-input
            id="state"
            v-model="value.state"
            type="text"
            placeholder="State"
            class="form-item-input"
            required />
        </el-form-item>
      </div>
    </transition>
    <portal v-if="allowReset" to="back-button-link">
      <a class="back-link" href="#" @click.prevent="resetQuestion">
        <i class="el-icon-back" />
      </a>
    </portal>
  </div>
</template>
<script>
import smartyStreetService from '@/services/smarty-street';

const NOT_FOUND_DESCRIPTION = "Can't find it? Add it manually";

const addNotFoundOption = function(suggestions) {
  suggestions.push({
    description: NOT_FOUND_DESCRIPTION
  });
  return suggestions;
};

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
      searchingAddress: false,
      parsingAddress: false,
      country: this.$store.getters.getQuestionAnswer('country') || '',
      address: '',
      suggestions: []
    };
  },
  computed: {
    is_focus() {
      return this.open && (this.searchingAddress || this.suggestions.length > 0);
    },
    showAddressSearch() {
      return !this.value.hasOwnProperty('address') || this.value.address === null;
    },
    allowReset() {
      return (
        !this.showAddressSearch && !this.$store.getters.getQuestionAnswer(this.question.key).hasOwnProperty('address')
      );
    }
  },
  methods: {
    async handleAddressSearch(searchQuery) {
      this.searchingAddress = true;
      try {
        const suggestions = await this.$store.dispatch('getAddressSuggestions', `${this.country}, ${searchQuery}`);
        this.suggestions = addNotFoundOption(suggestions);
        this.searchingAddress = false;
      } catch (e) {
        if (!e.isCancel) {
          this.suggestions = addNotFoundOption([]);
          this.searchingAddress = false;
        }
      }
    },
    async handleAddressSelected(value) {
      if (value === NOT_FOUND_DESCRIPTION) {
        this.address = '';
        this.$emit('input', {
          address: ''
        });
      } else {
        this.address = value;
        this.$emit('loading', true);
        const countryRemoved = value.replace(new RegExp('(,\\s*)?' + this.country + '$', 'i'), '');
        let response = { address: '' };
        await smartyStreetService
          .getParsedAddress(this.country, countryRemoved)
          .then(resp => (response = resp), () => {});
        this.$emit('input', response);
        this.$emit('loading', false);
      }
    },
    resetQuestion() {
      this.$emit('input', {});
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.showAddressSearch) {
          if (this.address.length > 0) {
            this.handleAddressSelected(this.address);
            return reject();
          }
          return reject({ message: 'You must select an address' });
        }
        return resolve();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  display: block;
}

.el-button {
  position: relative;
}

.el-icon-refresh {
  position: relative;
  right: -3%;
}

.el-form-item {
  margin-bottom: 12px;
}
</style>
