<template>
  <div class="question-phone-components">
    <input class="hidden" type="text" @focus="focusNumber">
    <el-form-item>
      <el-tel-input
        id="phone-input-element"
        ref="input"
        v-model="phoneNumber"
        :preferred-countries="['CA', 'US', 'CN']"
        class="tel-input"
        default-country="CA"
        popper-class="mw-550"
        @input-details="handleElTelInput" />
    </el-form-item>
  </div>
</template>
<script>
import ElTelInput from '@jumbleberry/el-tel-input';

const getVerificationStatus = function(value) {
  if (value.hasOwnProperty('phone_verification_status')) {
    return parseInt(value.phone_verification_status) === 1;
  }
  return false;
};

const getFormattedNumber = function(number, countryCode) {
  return `+${countryCode}${number}`;
};

export default {
  components: {
    ElTelInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    const phoneNumber = this.value.phone ? getFormattedNumber(this.value.phone, this.value.country_code) : '';
    return {
      previousPhoneNumber: phoneNumber,
      phoneNumber: phoneNumber,
      verified: getVerificationStatus(this.value)
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    focusNumber() {
      this.$refs.input.$el.querySelectorAll('input')[1].focus();
    },
    handleElTelInput(value) {
      const verified = this.verified && value.number === this.previousPhoneNumber;
      let verificationStatus = verified ? '1' : '0';
      if (this.$route.query.hasOwnProperty('re-send') && this.$route.query['re-send'] === '1') {
        verificationStatus = '0';
      }
      this.$emit('input', {
        phone: value.nationalNumber,
        country_code: value.countryCallingCode,
        phone_verification_status: verificationStatus
      });
      if (!verified) {
        this.$store.dispatch('surveyIncomplete');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hidden {
  border: none;
  display: inline;
  float: left;
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
}
</style>

<style lang="scss">
@import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

.question-phone-components {
  .tel-input,
  .tel-input:hover {
    border: 1px solid $--color-primary;
    border-radius: 3px;
  }

  .el-input-group__prepend {
    background: $--jb-bg-primary-color;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  .el-tel-input {
    .el-input__inner,
    .el-input__inner:hover {
      border: none;
      color: $--color-primary !important;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 40px;
    }

    .el-input--prefix .el-input__prefix {
      padding-left: 10px;
    }

    .el-flagged-label {
      align-items: center;
      height: 100%;
    }

    .el-select {
      margin-left: 0;
      margin-right: 0;

      .el-input {
        width: 140px;

        .el-input__inner {
          border-radius: 0;
          border-right: 1px solid $--color-primary;
          padding: 15px 15px 15px 55px;
        }

        @media (max-width: $--sm) {
          width: 100px;

          .el-input__inner {
            padding-left: 15px;
          }

          .el-input__prefix {
            display: none;
          }
        }
      }
    }
  }
}
</style>
