<template>
  <div class="question-slide-component">
    <el-form-item prop="slide" class="slide-item">
      <div class="slide-label-cont">
        <label class="label">{{ question.question }}</label>
        <a
          v-if="question.toggle"
          class="toggle"
          @click.stop="disabled = !disabled">{{ disabled ? "I'll Share" : question.toggle }}</a>
      </div>
      <vue-slider
        ref="slider"
        v-model="answer"
        :dot-size="32"
        :height="10"
        :duration="0.3"
        :data="question.options"
        :marks="true"
        :disabled="disabled"
        :tooltip="disabled ? 'none' : 'always'"
        class="slider-cont"
        tooltip-placement="bottom" />
    </el-form-item>
  </div>
</template>
<script>
const DISABLED_ANSWER = 'None';
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      answer: '',
      disabled: false
    };
  },
  watch: {
    disabled(newState) {
      newState ? this.$emit('input', DISABLED_ANSWER) : (this.answer = this.question.options[0]);
    },
    answer() {
      this.$emit('input', this.answer);
    }
  },
  beforeMount() {
    this.answer = this.value !== '' ? this.value : this.question.options[0];
    if (this.value === DISABLED_ANSWER) {
      this.disabled = true;
    }
  }
};
</script>
<style lang="scss">
.question-slide-component {
  margin-bottom: 35px;
  text-align: left;

  &:last-child {
    margin-bottom: 32px;
  }

  .slide-item {
    margin: 0 24px 10px 24px;
  }

  .slide-label-cont {
    .label {
      font-size: 18px;
      font-weight: 600;
      color: $--clb-color__headings;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
      line-height: 24px;
      left: 0;
      position: relative;
    }

    .toggle {
      float: right;
      cursor: pointer;
    }
  }

  .vue-slider {
    padding: 12px 8px 40px 0 !important;

    .vue-slider-rail {
      background: #ececec;
      cursor: pointer;

      .vue-slider-mark-step {
        width: 1px;
        height: 8px;
        left: 50%;
        top: 20px;
        border-radius: 0;
      }

      .vue-slider-piecewise-dot {
        background: #cfcfcf;
        border-radius: 0;
        height: 95%;
        width: 39%;
        visibility: visible !important;
      }
    }

    &.vue-slider-ltr {
      .vue-slider-mark-label {
        margin-top: 20px;
      }

      .vue-slider-process {
        background: $--color-primary;
      }
    }

    .vue-slider-dot-tooltip-inner {
      border-color: $--jb-light-primary-color;
      background-color: $--jb-light-primary-color;
      line-height: 32px;
      padding: 4px 16px;
      font-size: 16px;
      font-weight: 600;

      @media (max-width: $--sm) {
        padding: 4px 8px;
        line-height: 28px;
        font-size: $--jb-font-size-small;
      }
    }
  }
}
</style>
