<template>
  <div class="question-multiselect-component">
    <el-form-item :class="{ is_focus }" class="inline-select">
      <el-select
        v-if="options.length"
        v-select-close-on-blur
        :value="value"
        :placeholder="question.question"
        :multiple-limit="question.limit || 0"
        :allow-create="question.option_other"
        :default-first-option="!value || value.length === 0"
        :popper-append-to-body="false"
        class="multi-select-item"
        filterable
        multiple
        popper-class="mw-550 inline"
        no-match-text="No Match Found"
        @visible-change="is_focus = !is_focus"
        @change="$emit('input', $event)">
        <el-option
          v-for="option in options"
          :id="`multiselect-option-${option.id}`"
          :key="option.id"
          :label="option.name"
          :value="option.id" />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      is_focus: false
    };
  },
  computed: {
    options() {
      return this.question.options || this.$store.getters.questionOptions(this.question.endpoint);
    }
  },
  created() {
    if (this.question.endpoint) {
      this.$store.dispatch('fetchQuestionOptions', this.question);
    }
  }
};
</script>
