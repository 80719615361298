<template>
  <div class="question-group-component">
    <el-form novalidate @submit.native.prevent="handleFormQuestionSubmit">
      <component
        :is="subQuestionTypeComponent(subQuestion)"
        v-for="(subQuestion, key, index) in question.questions"
        ref="survey-question-component-container"
        :key="subQuestion.question"
        v-model="value[subQuestion.key]"
        :class="`question-group-sub-question question-group-sub-question-${index} survey-question-type-${key}`"
        :question="subQuestion" />
    </el-form>
  </div>
</template>
<script>
import QuestionMixin from '@/mixins/questionMixin';

export default {
  mixins: [QuestionMixin],
  props: {
    question: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    subQuestionTypeComponent(subQuestion) {
      return `question-${subQuestion.type.replace('_', '-')}`;
    }
  }
};
</script>
