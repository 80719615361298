<template>
  <el-row justify="center" align="middle">
    <el-col :sm="{span: 16, offset: 4}">
      <transition name="el-fade-in" mode="out-in">
        <survey-question v-if="question" :key="question.key" :question="question" />
      </transition>
    </el-col>
  </el-row>
</template>

<script>
import SurveyQuestion from '@/components/signup/survey/SurveyQuestion';
export default {
  name: 'SurveyView',
  components: {
    SurveyQuestion
  },
  computed: {
    question() {
      const question = this.$store.getters.getQuestionByKey(this.$route.params.id);
      if (!question) {
        this.$router.replace('/signup');
      }
      return question;
    }
  }
};
</script>
