<template>
  <div class="question-dropdown-component">
    <el-form-item :class="{ is_focus }" class="inline-select">
      <el-select
        v-if="options.length"
        v-select-close-on-blur
        :value="value"
        :placeholder="question.question"
        :popper-append-to-body="false"
        :default-first-option="!value"
        filterable
        popper-class="mw-550 inline"
        no-match-text="No Match Found"
        @visible-change="is_focus = !is_focus"
        @change="submit">
        <el-option
          v-for="item in options"
          :id="`dropdown-option-${item.id}`"
          :key="item.id"
          :label="item.name"
          :value="item[valueKey]" />
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      is_focus: false
    };
  },
  computed: {
    options() {
      return this.$store.getters.questionOptions(this.question.endpoint);
    },
    valueKey() {
      return this.question.key === 'country' ? 'name' : 'id';
    }
  },
  created() {
    this.$store.dispatch('fetchQuestionOptions', this.question);
  },
  methods: {
    submit(val) {
      if (val) {
        this.$emit('input', val);
        this.$emit('submit');
      }
    }
  }
};
</script>
