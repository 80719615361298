<template>
  <div :class="`survey-question-type-${question.key}`" class="survey-question-component">
    <el-form novalidate @submit.native.prevent="handleFormQuestionSubmit">
      <h2 class="survey-question-header">
        {{ question.title }}
      </h2>
      <p class="survey-question-sub-header">
        {{ question.details }}
      </p>
      <component
        :is="questionTypeComponent"
        ref="survey-question-component-container"
        :key="question.key"
        v-model="value"
        :question="question"
        @change-next-question-button-visibility="handleChangeNextQuestionButtonVisibility"
        @submit="submitQuestionWithDelay"
        @loading="handleQuestionLoading" />
      <el-button
        v-if="showNextQuestionButton"
        :loading="loading"
        type="primary"
        class="next-button"
        native-type="submit">
        {{ nextQuestionButtonText }} <i class="el-icon-arrow-right" />
      </el-button>
    </el-form>
  </div>
</template>
<script>
import QuestionMixin from '@/mixins/questionMixin';
import QuestionGroup from '@/components/signup/questions/QuestionGroup';

/**
 * Check if a parent has a specific class
 * @param element Node
 * @param parentSelector String
 * @param max int
 * @param current int
 * @returns {boolean}
 */
const hasParent = function(element, parentSelector, max = 5, current = 0) {
  if (max < current) {
    return false;
  }
  return (
    element.parentNode &&
    (element.parentNode.matches(parentSelector) || hasParent(element.parentNode, parentSelector, max, ++current))
  );
};

export default {
  components: {
    QuestionGroup
  },
  mixins: [QuestionMixin],
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null,
      loading: false,
      value: JSON.parse(JSON.stringify(this.$store.getters.getQuestionAnswer(this.question.key))),
      showNextQuestionButton: true
    };
  },
  computed: {
    questionTypeComponent() {
      return `question-${this.question.type.replace('_', '-')}`;
    },
    surveyStatus() {
      return this.$store.state.survey.status;
    },
    questionComponent() {
      return this.$refs['survey-question-component-container'];
    },
    nextQuestionButtonText() {
      return this.$store.getters.surveyStatus === 'complete' ? 'Back to Summary' : 'Next Question';
    }
  },
  mounted() {
    this.scrollToTop();
    this.focusMainInput();
  },
  methods: {
    handleQuestionLoading(loading) {
      this.loading = loading;
    },
    handleFormQuestionSubmit() {
      if (!this.questionComponent.hasOwnProperty('validate')) {
        this.submitQuestionWithDelay(0);
      } else {
        this.questionComponent
          .validate()
          .then(() => {
            this.submitQuestionWithDelay(0);
          })
          .catch(e => {
            e && e.message && this.showErrorMessage(e.message);
          });
      }
    },
    submitQuestionWithDelay(delay = 200) {
      if (this.loading && !this.timeout) return;

      this.loading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.submitQuestion();
      }, delay);
    },
    submitQuestion() {
      this.timeout = null;
      this.$store
        .dispatch('nextQuestion', {
          question: this.question,
          answer: JSON.parse(JSON.stringify(this.value))
        })
        .then(payload => {
          if (this.questionComponent.hasOwnProperty('afterSuccessResponse')) {
            this.questionComponent.afterSuccessResponse(payload);
          }
          this.$router.push(payload.redirect);
        })
        .catch(e => {
          if (e.response.hasOwnProperty('status') && e.response.status === 403) {
            this.$message({
              showClose: true,
              message: 'You have already completed the survey'
            });
            this.$router.replace('/signup/survey/finish');
          } else {
            this.showErrorMessage(e.message);
          }
          this.loading = false;
        });
    },
    showErrorMessage(message) {
      this.$message({
        showClose: true,
        message: message,
        type: 'error'
      });
    },
    scrollToTop() {
      const html = document.querySelector('html');
      const header = document.querySelector('.survey-question-header');
      const backLink = document.querySelector('.back-link');
      const maxOffset =
          Math.min(header.getBoundingClientRect().top, (backLink || header).getBoundingClientRect().top) +
          (window.pageYOffset || document.documentElement.scrollTop) -
          15;

      // If user scrolled down past the header of the question, bump them back up to the top
      if (html.scrollTop > maxOffset) html.scrollTo(0, maxOffset);
    },
    focusMainInput() {
      if (this.questionComponent) {
        const inputs = this.questionComponent.$el.querySelectorAll('input');
        const validInput = Array.from(inputs).find(input => {
          return !hasParent(input, '.el-input-group__prepend');
        });
        if (validInput) {
          validInput.focus();
        }
      }
    },
    handleChangeNextQuestionButtonVisibility({ visible }) {
      this.showNextQuestionButton = visible;
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-question-header {
  margin-bottom: 0;
  margin-top: 0;
}

.survey-question-sub-header {
  color: $--jb-survey-sub-header;
  font-size: $--jb-font-size-small;
  margin-bottom: 32px;
  margin-top: 8px;
}

.next-button {
  margin-top: 20px;
}
</style>
