<template>
  <div class="question-phone-component floating-label">
    <el-form-item class="floating-label" prop="text">
      <el-input
        v-for="(digit, index) in phone"
        ref="input"
        :key="index"
        v-model="phone[index]"
        maxlength="4"
        type="tel"
        min="0"
        max="9"
        placeholder="0"
        class="form-item-input"
        @focus="focusInput(index)"
        @keydown.backspace.native.prevent="handleBack(index)" />
    </el-form-item>
    <div class="no-code">
      Didn't get the code? <router-link to="/signup/survey/phone_number?re-send=1" class="link">
        Send again
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      phone: { 0: '', 1: '', 2: '', 3: '' },
      ignoreChange: false
    };
  },
  watch: {
    'phone.0'(newVal, oldVal) {
      this.handleInput(0, newVal, oldVal);
    },
    'phone.1'(newVal, oldVal) {
      this.handleInput(1, newVal, oldVal);
    },
    'phone.2'(newVal, oldVal) {
      this.handleInput(2, newVal, oldVal);
    },
    'phone.3'(newVal, oldVal) {
      this.handleInput(3, newVal, oldVal);
    }
  },
  mounted() {
    this.$emit('input', '');
  },
  methods: {
    focusInput(key) {
      key = parseInt(key);
      if (key < 0) key = 0;
      else if (key > 3) key = 3;

      this.$refs.input[key].select();
    },
    handleInput(key, val, old) {
      if (this.ignoreChange || !val || val === '') return;

      this.ignoreChange = true;
      this.$nextTick(() => {
        val = val.replace(/[^0-9]+/g, '');
        old = old.replace(/[^0-9]+/g, '');

        if (!val || val.length <= 2 || key === 3) {
          this.phone[key] = val.replace(old, '').substr(0, 1);
          key = val ? key + 1 : key;
        } else if (val.length > 1) {
          val.split('').some(v => {
            this.phone[key] = v;
            return key++ >= 3;
          });
        }
        this.$nextTick(() => {
          const phone = Object.values(this.phone).join('');
          this.$emit('input', phone);
          if (phone.length === 4 && key >= 3) {
            this.$emit('submit');
          }

          this.focusInput(Math.min(key, 3));
          this.ignoreChange = false;
        });
      });
    },
    handleBack(key) {
      if (this.phone[key] === '' && key > 0) {
        this.phone[key - 1] = '';
        return this.focusInput(key - 1);
      } else {
        this.phone[key] = '';
      }
    },
    afterSuccessResponse() {
      const questionKey = 'phone_number';
      const phoneNumberAnswer = this.$store.getters.getQuestionAnswer('phone_number');
      phoneNumberAnswer.phone_verification_status = '1';
      this.$store.dispatch('setQuestionAnswer', {
        questionKey: questionKey,
        answer: phoneNumberAnswer
      });
    }
  }
};
</script>
<style lang="scss">
.question-phone-component {
  .form-item-input {
    display: inline-block;
    margin-right: 9.33%;
    text-align: center;
    width: 18%;

    .el-input__inner {
      text-align: center;
    }
  }

  .form-item-input::placeholder {
    text-align: center;
  }

  .form-item-input:last-child {
    margin-right: 0 !important;
  }

  .no-code {
    color: $--jb-survey-sub-header;
    font-size: $--jb-font-size-small;
    position: relative;
    top: 112px;
    height: 0;
  }

  .link {
    color: $--tooltip-color;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
