<template>
  <div class="question-checkboxes-component">
    <slot />
    <el-collapse-transition>
      <el-form-item v-if="selected">
        <el-input
          id="other-option"
          ref="other-option-input"
          v-model="answer"
          type="text"
          placeholder="Please Specify"
          class="form-item-input"
          required />
      </el-form-item>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  props: ['selected', 'preloaded-answer'],
  data() {
    return {
      answer: ''
    };
  },
  watch: {
    selected(newState) {
      if (newState === true) {
        this.$emit('input', this.answer);
        this.$nextTick(() => {
          if (this.$refs['other-option-input']) {
            this.$refs['other-option-input'].$el.querySelector('input').select();
          }
        });
      }
    },
    answer() {
      if (this.selected === true) {
        this.$emit('input', this.answer);
      }
    }
  },
  beforeMount() {
    this.answer = this.preloadedAnswer;
  }
};
</script>

<style lang="scss">
.question-checkboxes-component {
  .form-item-input .el-input__inner {
    text-align: center;
  }
}
</style>
