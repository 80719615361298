<template>
  <div class="question-selectother-component">
    <el-form-item :class="{ is_focus }" class="inline-select">
      <el-select
        v-model="selectedOption"
        v-select-close-on-blur
        :placeholder="question.question"
        :default-first-option="!value"
        :popper-append-to-body="false"
        popper-class="mw-550 inline"
        no-match-text="No Match Found"
        filterable
        @visible-change="is_focus = !is_focus"
        @change="handleOptionChanged">
        <el-option
          v-for="(option, index) in options"
          :id="`selectother-option-${index}`"
          :key="option"
          :value="option"
          :label="option" />
      </el-select>
    </el-form-item>
    <el-collapse-transition>
      <el-form-item v-if="selectedOption === otherOptionValue">
        <el-input
          id="other-option"
          ref="other-option-input"
          :value="otherOptionText"
          type="text"
          placeholder="Please Specify"
          class="form-item-input"
          required
          @input="handleOtherOption" />
      </el-form-item>
    </el-collapse-transition>
  </div>
</template>
<script>
const OTHER_OPTION_VALUE = 'Other';

function getSelectedOption(answer, options) {
  if (!answer) {
    return '';
  }
  const selectedOption = options.find(o => o === answer);
  if (!selectedOption) {
    return OTHER_OPTION_VALUE;
  }
  return selectedOption;
}

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      is_focus: false,
      otherOptionValue: OTHER_OPTION_VALUE,
      otherOptionText: this.value !== OTHER_OPTION_VALUE ? this.value : '',
      selectedOption: getSelectedOption(this.value, this.question.options)
    };
  },
  computed: {
    options() {
      return this.question.options;
    }
  },
  methods: {
    isOptionSelected(option) {
      return this.value === option;
    },
    handleOptionChanged(option) {
      if (option === OTHER_OPTION_VALUE) {
        this.handleOtherOption('');
        this.$nextTick(() => {
          if (this.$refs['other-option-input']) {
            this.$refs['other-option-input'].$el.querySelector('input').focus();
          }
        });
      } else {
        this.$emit('input', option);
        this.$emit('submit');
      }
    },
    handleOtherOption(option) {
      this.otherOptionText = option;
      this.$emit('input', option);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.question-selectother-component {
  .form-item-input .el-input__inner {
    text-align: center;
  }
}
</style>
