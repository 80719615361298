<template>
  <div class="question-order-component">
    <draggable v-model="orderedAnswers" :options="{delay:60, forceFallback: true}" @input="handleOrderChange">
      <transition-group name="list-complete">
        <div
          v-for="(option, index) in orderedAnswers"
          :id="`order-option-${index}`"
          :key="option"
          class="draggable-item btn-100 el-button">
          <span>{{ option }}</span>
          <div class="badge-item">
            {{ index + 1 }}
          </div>
          <div class="ranking-icon-cont">
            <i class="el-icon-caret-top" @click.prevent="handleClickMoveQuestionIcon(index, -1)" />
            <i class="el-icon-caret-bottom" @click.prevent="handleClickMoveQuestionIcon(index, 1)" />
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  components: {
    Draggable
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    let orderedAnswers = [];
    if (this.value.length) {
      orderedAnswers = this.question.options.sort((a, b) => this.indexOrLast(this.value, a) - this.indexOrLast(this.value, b));
    } else {
      orderedAnswers = this.question.options;
    }
    return {
      orderedAnswers
    };
  },
  created() {
    this.handleOrderChange(this.orderedAnswers);
  },
  methods: {
    indexOrLast(arr, key) {
      const index = arr.indexOf(key);
      return index !== -1 ? index : (arr.length + 1);
    },
    handleOrderChange(orderedAnswers) {
      this.$emit('input', orderedAnswers);
    },
    handleClickMoveQuestionIcon(index, direction) {
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex > this.orderedAnswers.length - 1) {
        return false;
      }
      this.orderedAnswers.splice(newIndex, 0, this.orderedAnswers.splice(index, 1)[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-complete-item {
  transition: all 1.5s ease-in;
}

.list-complete-enter,
.list-complete-leave-active {
  opacity: 0;
}

.question-order-component {
  .draggable-item {
    margin: 0 0 12px 0;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    background: #fff;
    border: 1px solid $--border-color-base;
    border-radius: $--border-radius-base;
    color: $--color-text-regular;
    font-weight: 600;
    padding: $--button-padding-vertical $--button-padding-horizontal;
    display: inline-block;
    line-height: 36px;

    .badge-item {
      box-sizing: border-box;
      color: $--border-color-base;
      height: 58px;
      left: 16px;
      line-height: 58px;
      position: absolute;
      top: 0;
      transition: color 0.3s ease;
    }

    &:hover {
      .badge-item {
        color: $--color-primary;
      }
    }

    &:focus,
    &:hover {
      cursor: move;
    }

    &:active,
    &:focus,
    &:hover {
      &:not(.sortable-chosen):not(.sortable-ghost) {
        background: #fff !important;
        border: 1px solid $--border-color-base !important;
        color: $--color-text-regular !important;

        .badge-item {
          color: $--border-color-base !important;
        }
      }
    }

    &.sortable-chosen,
    &.sortable-ghost {
      background: $--color-light !important;
      border-color: $--jb-light-primary-color !important;
      color: $--jb-light-primary-color !important;
    }
  }

  .ranking-icon-cont {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 13px;
    top: 13px;

    i {
      cursor: pointer;
    }
  }
}
</style>
