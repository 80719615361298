import QuestionSelect from '@/components/signup/questions/QuestionSelect';
import QuestionSelectother from '@/components/signup/questions/QuestionSelectother';
import QuestionText from '@/components/signup/questions/QuestionText';
import QuestionAddress from '@/components/signup/questions/QuestionAddress';
import QuestionPhoneNumber from '@/components/signup/questions/QuestionPhoneNumber';
import QuestionPhoneVerification from '@/components/signup/questions/QuestionPhoneVerification';
import QuestionDropdown from '@/components/signup/questions/QuestionDropdown';
import QuestionMultiselect from '@/components/signup/questions/QuestionMultiselect';
import QuestionOrder from '@/components/signup/questions/QuestionOrder';
import QuestionSlide from '@/components/signup/questions/QuestionSlide';
import QuestionCheckboxes from '@/components/signup/questions/QuestionCheckboxes';

export default {
  components: {
    QuestionSelect,
    QuestionText,
    QuestionAddress,
    QuestionPhoneNumber,
    QuestionPhoneVerification,
    QuestionDropdown,
    QuestionMultiselect,
    QuestionOrder,
    QuestionSelectother,
    QuestionSlide,
    QuestionCheckboxes
  }
};
