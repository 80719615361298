<template>
  <div class="question-select-component">
    <el-button
      v-for="(option, index) in question.options"
      :id="`button-option-${index}`"
      :key="option"
      :class="{ active: isOptionSelected(option) }"
      class="btn-100"
      @click="setFixAnswer(option)">
      <transition name="fade-in">
        <i v-if="isOptionSelected(option)" class="el-icon-success" />
      </transition>
      <span class="align">
        <Component :is="optionIconName(option)" v-if="optionsHaveIcons" class="icon" />
        {{ option }}
      </span>
    </el-button>

    <QuestionOther
      v-if="showOtherOption"
      :selected="otherOptionSelected"
      :preloaded-answer="isFixedAnswer() ? '' : answer"
      @input="setOtherOptionAnswer">
      <el-button
        :class="{ active: otherOptionSelected }"
        class="btn-100"
        @click="otherOptionSelected = !otherOptionSelected">
        Other
        <transition name="fade-in">
          <i v-if="otherOptionSelected" class="el-icon-success" />
        </transition>
      </el-button>
    </QuestionOther>
  </div>
</template>
<script>
// Todo: dynamically load images
import email from '@/assets/svg/brands/email.svg';
import slack from '@/assets/svg/brands/slack.svg';
import telegram from '@/assets/svg/brands/telegram.svg';
import skype from '@/assets/svg/brands/skype.svg';
import wechat from '@/assets/svg/brands/wechat.svg';
import QuestionOther from './subtype/QuestionOther';

export default {
  components: {
    email,
    slack,
    telegram,
    skype,
    wechat,
    QuestionOther
  },
  props: {
    value: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      answer: '',
      otherOptionSelected: false
    };
  },
  computed: {
    showOtherOption() {
      return this.question.hasOwnProperty('option_other');
    },
    optionsHaveIcons() {
      return Object.values(this.question.options).every(option => this.optionHasIcon(option));
    }
  },
  beforeMount() {
    this.answer = this.value;
    this.otherOptionSelected = this.answer && !this.isFixedAnswer();
  },
  methods: {
    isOptionSelected(option) {
      return this.answer === option;
    },
    isFixedAnswer() {
      return this.question.options.includes(this.answer);
    },
    setFixAnswer(value) {
      this.otherOptionSelected = false;
      this.answer = value;
      this.$emit('input', this.answer);
      this.$emit('submit');
    },
    setOtherOptionAnswer(value) {
      this.answer = value;
      this.$forceUpdate(); // force update to rerender component with new answer since this.answer is not used in the template
      this.$emit('input', this.answer);
    },
    optionIconName(option) {
      return option.toLowerCase();
    },
    optionHasIcon(option) {
      return typeof this.$options.components[option.toLowerCase()] === 'object';
    }
  }
};
</script>
<style lang="scss" scoped>
.question-select-component {
  button {
    line-height: 24px;
    margin-bottom: 12px;

    .align {
      display: inline-block;
      text-align: center;
      max-width: 80%;
      line-height: 17px;
      margin-left: 0;
    }

    .icon {
      left: -8px;
      line-height: 30px;
      margin-bottom: -6px;
      position: relative;
      transition: $--all-transition;
      fill: $--color-text-regular;
    }

    &.active .icon,
    &:hover .icon {
      fill: $--color-primary;
    }

    .el-icon-success {
      line-height: 24px;
      font-size: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
</style>
