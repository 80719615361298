import axios from 'axios';

export default {
  async getParsedAddress(country, address) {
    if (process.env.NODE_ENV !== 'production') {
      return {
        address: process.env.VUE_APP_SURVEY_QUESTION_ADDRESS_ADDRESS,
        address_2: '',
        city: process.env.VUE_APP_SURVEY_QUESTION_ADDRESS_CITY,
        state: process.env.VUE_APP_SURVEY_QUESTION_ADDRESS_STATE,
        postal_code: process.env.VUE_APP_SURVEY_QUESTION_ADDRESS_POSTAL_CODE
      };
    }

    const response = await axios
      .get(
          `${process.env.VUE_APP_SMARTYSTREET_API_URL}/verify?auth-id=${
            process.env.VUE_APP_SMARTYSTREET_API_KEY
          }&country=${country}&address1=${address}`
      )
      .catch(() => {});
    if (response && response.data && response.data.length) {
      return {
        address: response.data[0].address1,
        address_2: '',
        city: response.data[0].components.locality,
        state: response.data[0].components.administrative_area,
        postal_code: response.data[0].components.postal_code
      };
    }

    return {
      address: '',
      address_2: '',
      city: '',
      state: '',
      postal_code: ''
    };
  }
};
