<template>
  <div class="question-text-component floating-label">
    <el-form-item class="floating-label" prop="text">
      <el-input
        id="text"
        :placeholder="question.title"
        :value="value"
        type="text"
        class="form-item-input"
        @input="$emit('input', $event)" />
    </el-form-item>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  }
};
</script>
